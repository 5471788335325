import { Heading, Paragraph, TimeUtil } from 'cuenect-web-core'
import i18n from 'i18next'
// tslint:disable-next-line: ordered-imports
import styled from '@emotion/styled'

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Carousel,
  ContentContainer,
  ExternalContent,
  Furioos,
  FurioosPlayer,
  FurioosShowroom,
  InnerContainer,
  LineContainer,
  ShowRoom,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'
import {
  AnalyticsTransformer,
  useModalContext,
  useVodContext,
} from './../utility'
import { mq } from './../utility/breakpoint'

interface SDKMessageFairtouchVideo {
  messageType: FurioosMessageType.OPEN_LINK
  target: 'overlay'
  linkType: FurioosLinkType.FAIRTOUCH_VIDEO_PLAYER
  uids: string[]
}

interface SDKMessageFirtouchStoryApp {
  uid: string
  messageType: FurioosMessageType.OPEN_LINK
  target: 'overlay'
  linkType: FurioosLinkType.FAIRTOUCH_STORY_APP
}

interface SDKMessageAgenda {
  messageType: FurioosMessageType.OPEN_LINK
  target: 'overlay'
  linkType: FurioosLinkType.AGENDA
  uid: string
}

interface SDKMessageGetInTouch {
  messageType: FurioosMessageType.OPEN_LINK
  target: 'overlay'
  linkType: FurioosLinkType.GET_IN_TOUCH
}

interface SDKMessageFairtouchWebPlayer {
  messageType: FurioosMessageType.OPEN_LINK
  target: 'overlay'
  linkType: FurioosLinkType.FAIRTOUCH_WEB_PLAYER
  uid: string
}

interface SDKMessageUrl {
  url: string
  messageType: FurioosMessageType.OPEN_LINK
  linkType: FurioosLinkType.URL
}

interface SDKMessageTracking {
  messageType: FurioosMessageType.TRACKING
  params: string[]
}

interface SDKReadyMessage {
  init: 'ready'
}

const isSDKReadyMessage = (x: unknown): x is SDKReadyMessage => {
  const { init } = x as SDKReadyMessage

  return init && init === 'ready'
}

enum FurioosLinkType {
  FAIRTOUCH_VIDEO_PLAYER = 'fairtouchVideoplayer',
  FAIRTOUCH_STORY_APP = 'fairtouchStoryapp',
  FAIRTOUCH_WEB_PLAYER = 'fairtouchWebplayer',
  AGENDA = 'agenda',
  GET_IN_TOUCH = 'getInTouch',
  URL = 'url',
}

enum FurioosMessageType {
  OPEN_LINK = 'openLink',
  TRACKING = 'tracking',
}

type ClusterId = '01' | '02' | '03' | '04'

const CLUSTER_DATES = [
  TimeUtil.getUtc('2021-10-06T13:00Z'),
  TimeUtil.getUtc('2021-10-20T13:00Z'),
  TimeUtil.getUtc('2021-11-03T13:00Z'),
  TimeUtil.getUtc('2021-11-17T13:00Z'),
]

const CLUSTER_DATE_MAPPING: ClusterId[][] = [
  ['02', '03', '04'],
  ['03', '04'],
  ['04'],
  [],
]

const APP_READY_PARAM = 'appReady'

const getClustersToHideIndex = (): number => {
  const now = TimeUtil.getNowUtc()

  return CLUSTER_DATES.reduce<number>((acc, curr, index) => {
    if (now.isAfter(curr)) {
      return index
    }

    return acc
  }, 0)
}

const getClustersToHide = (): ClusterId[] =>
  CLUSTER_DATE_MAPPING[getClustersToHideIndex()]

const ShowroomPage = (params: PageParams) => {
  const clustFilterTimeOutId = React.useRef<number>()

  const {
    pageContext: { lang, program },
  } = params
  const { dispatch: openVod } = useVodContext()
  const { dispatch: dispatchModal } = useModalContext()

  const { t } = useTranslation('showroom')
  const [loaded, setLoaded] = React.useState(false)

  const furioosPlayerRef = React.useRef<FurioosPlayer>()

  const appReady = React.useRef<boolean>(false)

  const furioosPlayerRefCallback = React.useCallback(
    (player: FurioosPlayer) => {
      player.onAppStart(() => {
        console.log('ON APP START')

        setClusterFilter()
      })

      player.onStreamStart(() => {
        console.log('ON STREAM START')

        setClusterFilter()
      })

      player.onSDKMessage(sdkMessage => {
        try {
          const parsedMessage = JSON.parse(sdkMessage)
          const lccc = lang === 'de' ? '&lccc=de-DE' : ''

          console.log({ parsedMessage })

          if (isSDKReadyMessage(parsedMessage)) {
            onInitHook()

            return
          }

          if (parsedMessage.messageType === FurioosMessageType.TRACKING) {
            handleTracking(parsedMessage)

            return
          }

          switch (parsedMessage.linkType) {
            case FurioosLinkType.GET_IN_TOUCH:
              // const uri =
              //   lang === 'de'
              //     ? 'https://new.siemens.com/de/de/general/kontakt.html'
              //     : 'https://new.siemens.com/global/en/general/contact.html'

              const uri =
                'https://new.siemens.com/global/en/products/services/cybersecurity/contact.html'

              window.open(uri, '_blank', 'noopener')

              return
            case FurioosLinkType.FAIRTOUCH_WEB_PLAYER:
              openInOverlay(
                `https://d2vdrxov3sh01c.cloudfront.net/webplayer/index.html?uid=${parsedMessage.uid}&device=Cybers_2&langswitch=false${lccc}`
              )

              return
            case FurioosLinkType.FAIRTOUCH_STORY_APP:
              openInOverlay(
                `https://d2vdrxov3sh01c.cloudfront.net/storyapp/index.html?device=Cybers_1&uid=${parsedMessage.uid}${lccc}`
              )

              return
            case FurioosLinkType.FAIRTOUCH_VIDEO_PLAYER:
              const uids = (parsedMessage.uids as string[])
                .map(uid => `&uid=${uid}`)
                .join('')

              openInOverlay(
                `https://d1367kmfi5z2s8.cloudfront.net/videoplayer/index.html?device=Cybers_0&${uids}${lccc}`
              )

              return
            default:
              console.log('no handler for', { parsedMessage })
          }
        } catch (e) {
          console.error({ e })
        }
      })

      furioosPlayerRef.current = player
    },
    []
  )

  console.log({ index: getClustersToHideIndex(), cluster: getClustersToHide() })

  const transitionClusterFilter = () => {
    const index = getClustersToHideIndex()
    const nextIndex = index + 1

    if (nextIndex === CLUSTER_DATES.length) {
      return
    }

    const nextDate = CLUSTER_DATES[nextIndex]
    const now = TimeUtil.getNowUtc()
    const msToNextDate = nextDate.diff(now, 'millisecond')

    clustFilterTimeOutId.current = window.setTimeout(
      setClusterFilter,
      msToNextDate
    )
  }

  React.useEffect(() => {
    transitionClusterFilter()

    return () => {
      window.clearTimeout(clustFilterTimeOutId.current)
    }
  })

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const openInOverlay = (uri: string) => {
    dispatchModal({
      type: 'OPEN_WITH_COMPONENT',
      state: {
        component: ExternalContent,
        params: {
          uri,
          fullscreen: true,
          onClose: () => dispatchModal({ type: 'HIDE_MODAL' }),
        },
      },
    })
  }

  const checkAppReady = (data: SDKMessageTracking) => {
    // if the update's already complete, just return
    if (appReady.current) {
      return
    }

    if (data.params.includes(APP_READY_PARAM)) {
      appReady.current = true
      onAppReady()
    }
  }

  const handleTracking = (data: SDKMessageTracking) => {
    console.log({ data }, 'tracking')

    checkAppReady(data)
    AnalyticsTransformer.customEvent(data.params)
  }

  const setClusterFilter = () => {
    if (!furioosPlayerRef.current) {
      return
    }

    const filterIds = getClustersToHide()
    furioosPlayerRef.current.sendSDKMessage({
      messageType: 'clusterHide',
      filterIds,
    })
  }

  const setLanguage = () => {
    if (lang !== 'de' || !furioosPlayerRef.current) {
      // since 'en-US' is the default value we don't need to set it
      return
    }

    furioosPlayerRef.current.sendSDKMessage({
      messageType: 'language',
      lccc: 'de-DE',
    })
  }

  const onAppReady = () => {
    setLanguage()
  }

  const onInitHook = () => {
    setClusterFilter()
  }

  return (
    <PageFrame {...params} perectScroll={false} pageName="showroom">
      <FurioosShowroom ref={furioosPlayerRefCallback} />
    </PageFrame>
  )
}

export default ShowroomPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
  align?: string
  border?: boolean
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({
    theme: { colors, grid },
    fullheight,
    width,
    border = false,
    align = 'left',
  }) => `
  display:flex;
  flex-direction:column;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight
      ? `min-height:calc(100vh - 220px);
    ${[mq[3]]}{
      display:flex;
      align-items:center;
    }`
      : ''
  }

  ${border ? `border:1px solid ${colors.inputBorder}; padding:40px;` : ''}


  margin-top:120px;
  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
const Spacer = styled.div`
  grid-column: 1/37;
  margin: 15px 0;
  ${mq[3]} {
    margin: 45px 0;
  }
`
